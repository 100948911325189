import React, { useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UserListModel, UserModel } from '@app/models/UserModel';
import { ColumnsType } from 'antd/es/table';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doGetFeatureRequests, setFeatureRequests } from '@app/store/slices/dataSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Avatar, Input, Modal, Popconfirm, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, InfoOutlined, MailOutlined, PushpinOutlined } from '@ant-design/icons';
import { Card as CommonCard } from '@app/components/common/Card/Card';
import { getModeDisplay, getProfilePhoto, secondsToString } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';
import { deleteFeatureRequest, updateFeatureRequest, updateUser } from '@app/api/admin.api';
import { Switch } from '@app/components/common/Switch/Switch';
import { FeatureRequest } from './types';

const FeatureRequestsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const featureRequests = useAppSelector((state) => state.data.featureRequests);

  const [isLoading, setLoading] = useState(false);
  const [deleteOpenModal, setDeleteOpenModal] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [keyWord, setKeyWord] = useState('')
  const [filteredFeatureRequests, setFilteredFeatureRequests] = useState([])

  const fetchFeatureRequests = () => {
    setLoading(true);
    dispatch(doGetFeatureRequests())
      .unwrap()
      .then((res) => {
        dispatch(setFeatureRequests(res));
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFeatureRequests();
  }, []);

  useEffect(() => {
    setFilteredFeatureRequests(keyWord != '' ? featureRequests.filter(data => {
      return data.created_by.name?.toLowerCase().indexOf(keyWord.toLowerCase()) > -1
        || data.description?.toLowerCase().indexOf(keyWord.toLowerCase()) > -1
    }) : featureRequests);
  }, [keyWord, featureRequests])

  const onPin = (isPinned: boolean, recordId: number) => {
    const updatedFeature = { is_pinned: isPinned }
    updateFeatureRequest(recordId, updatedFeature)
      .then(res => {
        console.log('updatedFeature', res);
        const updatedFeatures = featureRequests.map(data => (data.id === res.id ? res : data));
        dispatch(setFeatureRequests(updatedFeatures));
        notificationController.success({ message: 'Post updated successfully' })
      })
      .catch(error => {
        notificationController.error({ message: error.message });
      })
  }

  const onDelete = (record: FeatureRequest) => {
    setDeleteLoading(true);

    deleteFeatureRequest(record.id)
      .then(_ => {
        setDeleteLoading(false);
        setDeleteOpenModal(null);
        fetchFeatureRequests();
        notificationController.success({ message: 'Post deleted successfully' })
      })
      .catch(error => {
        setDeleteLoading(false);
        setDeleteOpenModal(null);
        notificationController.error({ message: error.message });
      })
  }

  const columns: ColumnsType<FeatureRequest> = [
    {
      title: 'Created By',
      dataIndex: 'created_by',
      width: '15%',
      render: (_, { created_by }: FeatureRequest) => (
        <Space size={'small'}>
          <Avatar src={getProfilePhoto(created_by.profile_picture)} alt="User" shape="circle" size={40} />
          <Typography.Text>{created_by.name}</Typography.Text>
        </Space>
      ),
    },
    {
      title: 'Description',
      dataIndex: ['description']
    },
    {
      title: 'Votes and Comments',
      dataIndex: 'votesAndComments',
      render: (text: string, request: FeatureRequest) => (
        <Space direction='vertical'>
          {request.total_upvote !== null && <Typography.Text>{request.total_upvote + ' Up Votes'}</Typography.Text>}
          {request.total_downvote !== null && <Typography.Text>{request.total_downvote + ' Down Votes'}</Typography.Text>}
          {request.total_comments !== null && <Typography.Text>{request.total_comments + ' Comments'}</Typography.Text>}
        </Space>
      ),
    },
    {
      title: 'Pin',
      dataIndex: 'pin',
      width: 120,
      render: (text: string, request: FeatureRequest) => {
        return (
          <Space>
            <Switch
              checkedChildren="Pinned"
              unCheckedChildren="Pin"
              checked={request.is_pinned}
              onChange={(checked) => onPin(checked, request.id)}
              style={{ backgroundColor: request.is_pinned && 'green' }}
            />
          </Space>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '5%',
      render: (text: string, record: FeatureRequest) => {
        return (
          <Space>
            <Popconfirm
              visible={deleteOpenModal === record.id}
              title="Are you sure you want to delete this post?"
              okButtonProps={{ loading: deleteLoading }}
              onConfirm={() => onDelete(record)}
              onCancel={() => setDeleteOpenModal(null)}>
              <Button size="small" type="primary" icon={<DeleteOutlined />} onClick={() => !deleteLoading && setDeleteOpenModal(record.id)} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5, marginBottom: 20 }}>
        <Typography.Title level={3}>
          Feature Requests
        </Typography.Title>
        <Input style={{ width: '50%' }} placeholder={'Search by keyword'} value={keyWord} onChange={e => setKeyWord(e.target.value)} />
      </div>
      <CommonCard id="basic-table" padding="1.25rem 1.25rem 0">
        <Table columns={columns} dataSource={filteredFeatureRequests} loading={isLoading} scroll={{ x: 800 }} />
      </CommonCard>
    </>
  );
};

export default FeatureRequestsPage;
