import { UserModel } from '@app/models/UserModel';

export interface Feedback {
  id: number;
  title: string;
  body: string;
  is_read: boolean;
  response: string;
  user: number;
}

export const mockUser: UserModel = {
  id: 0,
  email: 'admin@easecushion.com',
  name: 'Admin',
  age: null,
  weight: null,
  height: null,
  phone_number: null,
  gender: 0,
  symptoms: null,
  profile_picture: null,
  device_id: null,
  is_active: true,
  groups: []
};
