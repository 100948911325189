import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../../api/firebase.api.js';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NotificationPage from '@app/pages/Notification/index';
import CreateContentPage from '@app/pages/Content/CreateContent';
import UsersPage from '@app/pages/Users';
import FeedbackPage from '@app/pages/Feedback';
import ContentPage from '@app/pages/Content';
import EditUserPage from '@app/pages/Users/EditUser';
import { useAppSelector } from '@app/hooks/reduxHooks';
import FeatureRequestsPage from '@app/pages/FeatureRequests';

const AnalyticsPage = React.lazy(() => import('@app/pages/Analytics'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const Analytics = withLoading(AnalyticsPage);
const PersonalInfo = withLoading(PersonalInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const isAdmin = user?.groups?.find(e => e.name === 'Admin') ?? false

  const protectedLayout = (
    <RequireAuth>
      <MainLayout/>
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={isAdmin ? <Analytics/> : <UsersPage />}/>
          <Route path={'users'} element={<UsersPage/>}/>
          <Route path={'users/edit/:id'} element={<EditUserPage/>}/>
          <Route path={'users/notify'} element={<NotificationPage/>}/>
          <Route path={'content'} element={<ContentPage/>}/>
          <Route path={'content/create'} element={<CreateContentPage/>}/>
          <Route path={'content/edit'} element={<CreateContentPage/>}/>
          <Route path={'notification'} element={<NotificationPage/>}/>
          <Route path={'feedback'} element={<FeedbackPage/>}/>
          <Route path={'feature-requests'} element={<FeatureRequestsPage/>}/>
          <Route path="profile" element={<ProfileLayout/>}>
            <Route path="personal-info" element={<PersonalInfo/>}/>
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback/>}>
          <Route path="login" element={<LoginPage/>}/>
          {/* <Route path="sign-up" element={<SignUpPage/>}/>
          <Route path="forgot-password" element={<ForgotPasswordPage/>}/>
          <Route path="security-code" element={<SecurityCodePage/>}/>
          <Route path="new-password" element={<NewPasswordPage/>}/> */}
        </Route>
        <Route path="/logout" element={<LogoutFallback/>}/>
        <Route path="/terms-of-service" element={<Redirect to={"https://easeseatingsystems.com/pages/terms-of-use"}/>} />
        <Route path="/privacy-policy" element={<Redirect to={"https://easeseatingsystems.com/pages/privacy-policy"}/>} />
      </Routes>
    </BrowserRouter>
  );
};

const Redirect = ({to}) => {
  useEffect(() => {
    window.location.href = to
  })
  return <></>
}