import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Form, Col, Row, message, Typography, Space, UploadProps, Upload, Image } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContentModel } from '@app/models/ContentModel';
import { getProfilePhoto } from '@app/utils/utils';
import { postContent, updateContent } from '@app/api/admin.api';
import { notificationController } from '@app/controllers/notificationController';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './custom-quill.css';

export interface ContentRequest {
  image: string | null;
  title: string | null;
  body: string | null;
  available_in_about_screen: boolean;
  available_in_explore_screen: boolean;
}

const initialContentValues: ContentRequest = {
  title: '',
  body: '',
  image: null,
  available_in_about_screen: false,
  available_in_explore_screen: false,
};

interface LocationState {
  record: ContentModel;
}

// Quill modules and formats configuration
const quillModules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }], [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

const quillFormats = [
  'header', 'size',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

const CreateContentPage: React.FC = () => {
  // const location = useLocation();
  // const state = location.state as LocationState;
  // const record = state && state.record;

  const [isUpdating, setIsUpdating] = useState(false)
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const { state } = useLocation();
  const record = state && (state as LocationState).record;

  useEffect(() => {
    if (record) {
      console.log("IS UPDATING")
      setIsUpdating(true)
      setImagePreviewUrl(record.image)
    }
  }, [record])

  const navigate = useNavigate();

  const onFinish = (values: any) => {
    const combinedValues = { ...record, ...values }
    console.log('combinedValues: ', combinedValues);
    return new Promise(() => {
      const bodyFormData = new FormData();
      bodyFormData.append('title', combinedValues.title);
      bodyFormData.append('body', combinedValues.body);
      bodyFormData.append('available_in_about_screen', combinedValues.available_in_about_screen);
      bodyFormData.append('available_in_explore_screen', combinedValues.available_in_explore_screen);
      if (combinedValues.image && combinedValues.image.file) {
        bodyFormData.append('image', combinedValues.image.file);
      } else {
        console.error('No valid image file found.');
      }

      if (isUpdating) {
        updateContent(record.id, bodyFormData)
          .then((res) => {
            console.log('res', res);
            notificationController.success({ message: 'Content updated successfully.' });
            navigate(-1);
          })
          .catch((error) => {
            console.log('error: ', error);
            notificationController.error({ message: error });
          });
      } else {
        postContent(bodyFormData)
          .then((res) => {
            console.log('res', res);
            notificationController.success({ message: 'Content uploaded successfully.' });
            navigate(-1);
          })
          .catch((error) => {
            console.log('error: ', error);
            notificationController.error({ message: error });
          });
      }
    });
  };

  const props: UploadProps = {
    beforeUpload: (file: any) => {
      const imageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml', 'image/tiff'];
      const isImage = imageTypes.includes(file.type);
      if (!isImage) {
        message.error(`${file.name} is not a valid image file`);
        return Upload.LIST_IGNORE
      }
      return false
    },
    onChange: (info) => {
      console.log("Upload onChange: ", info);
      const file = info.file;
      if (file) {
        setImagePreviewUrl(URL.createObjectURL(file));
      }
    },
  };

  return (
    <Space size={'large'} direction={'vertical'}>
      <Typography.Title level={3}>Content</Typography.Title>
      <Card>
        <Form
          name={'notification-form'}
          layout={'vertical'}
          initialValues={record}
          onFinish={onFinish}
          autoComplete={'off'}
        >
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24}>
              <Form.Item
                name={'title'}
                label={'Title'}
                rules={[{ required: true, message: 'Please input a title' }]}
                style={{ maxWidth: '40rem' }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Space size={'middle'} direction="vertical">
              {/* {initialContentValues && <Image style={{ marginLeft: 16 }} height={140} src={initialContentValues.file} />} */}
              <Col xs={24}>
                <Form.Item
                  name={'image'}
                  label={'Thumbnail'}
                  rules={[{ required: true, message: 'Please upload an image' }]}>
                    <Upload maxCount={1} {...props}>
                      <Button icon={<UploadOutlined />}>{isUpdating ? 'Change Image' : 'Upload Image'}</Button>
                    </Upload>
                </Form.Item>
                {imagePreviewUrl && <Image preview={false} height={200} src={getProfilePhoto(imagePreviewUrl)} />}
              </Col>
            </Space>
            <Col xs={24}>
              <Form.Item
                name={'body'}
                label={'Content'}
                rules={[{ required: true, message: 'Please input a description' }]}
              >
                <ReactQuill
                  modules={quillModules}
                  formats={quillFormats}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name={'available_in_about_screen'} valuePropName="checked" rules={[{ required: true }]}>
                <Checkbox>About Us</Checkbox>
              </Form.Item>
              <Form.Item name={'available_in_explore_screen'} valuePropName="checked" rules={[{ required: true }]}>
                <Checkbox>Explore</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type={'ghost'} block htmlType={'submit'}>
                  {isUpdating ? 'Update' : 'Post'}
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button danger type={'ghost'} block onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Space>
  );
};

export default CreateContentPage;
