import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Form, Col, Row, Typography, Space, Image, Modal } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from '@app/components/common/buttons/Button/Button';
import { getFeedbacks, getUsers, postFeedbackResponse } from '@app/api/admin.api';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { Feedback, mockUser } from '@app/pages/Feedback/types';
import { UserListModel, UserModel } from '@app/models/UserModel';

const FeedbackPage: React.FC = () => {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [users, setUsers] = useState<UserListModel[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [response, setResponse] = useState<string>('');
  const [activeFeedback, setActiveFeedback] = useState<Feedback | undefined>(undefined);
  const [activeFeedbackUser, setActiveFeedbackUser] = useState<UserListModel | undefined>(undefined);

  const subscribeFeedbacks = () => {
    getFeedbacks().then((res: Feedback[]) => {
      // console.log('feedbacks', res);
      setFeedbacks(res);
    });
  };

  const subscribeUsers = () => {
    getUsers()
      .then((res: UserListModel[]) => {
        // console.log('users', res);
        setUsers(res);
      })
      .then(() => {
        subscribeFeedbacks();
      });
  };

  const onReply = (fd: Feedback) => {
    setActiveFeedback(fd);
    setModalOpen(true);
  };

  const postResponse = () => {
    if (response != '' && activeFeedback) {
      postFeedbackResponse({
        ...activeFeedback,
        is_read: true,
        response,
      }).then(() => {
        setModalOpen(false);
        // subscribeFeedbacks();
        setFeedbacks(
          feedbacks.map((fd: Feedback) => {
            if (fd.id == activeFeedback.id) {
              return {
                ...activeFeedback,
                is_read: true,
                response,
              };
            }
            return fd;
          }),
        );
      });
    }
  };

  useEffect(() => {
    subscribeUsers();
  }, []);

  useEffect(() => {
    setActiveFeedbackUser(users.find((u: UserListModel) => u.user.id == activeFeedback?.user));
  }, [activeFeedback]);

  useEffect(() => {
    if (!isModalOpen) {
      setResponse('');
    }
  }, [isModalOpen]);

  return (
    <Space size={'small'} direction={'vertical'} style={{ display: 'flex' }}>
      <Typography.Title level={3}>Feedback</Typography.Title>
      {feedbacks.map((it: Feedback, id: number) => {
        const user: UserListModel = users?.find((_user: UserListModel) => _user.user.id == it.user) || { user: mockUser, time_spent: 0, user_mode: null };

        return (
          <Card style={{ width: '100%', padding: 2, marginBottom: 4 }} key={id} size={'small'}>
            <Row>
              <Col span={2}>
                <Image
                  width={60}
                  height={60}
                  src={
                    user.user.profile_picture?.split('?AWSAccessKeyId')[0] ||
                    'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
                  }
                  style={{ borderRadius: 30 }}
                />
              </Col>
              <Col span={8}>
                <div>
                  <Text>{user.user.name}</Text>
                </div>
                <Text type={'secondary'}>{user.user.email}</Text>
              </Col>
              <Col span={12} />
              <Col span={2}>{!it.response && <Button onClick={() => onReply(it)}>Reply</Button>}</Col>
            </Row>
            <Row style={{ marginTop: 4 }}>
              <Col span={24}>
                <Title level={5}>{it.title}</Title>
              </Col>
              <Col span={24}>
                <Text style={{ 'whiteSpace': 'pre-line' }} type={'secondary'}>{it.body}</Text>
              </Col>
            </Row>
            <Row style={{ marginTop: 8 }}>
              <Col span={23} push={1}>
                <Text italic>{it.response}</Text>
              </Col>
            </Row>
          </Card>
        );
      })}
      <Modal
        title={`Reply to ${activeFeedbackUser?.user.name || ''}'s request`}
        visible={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={postResponse}
      >
        <TextArea rows={6} onChange={(event) => setResponse(event.target.value)}>
          {response}
        </TextArea>
      </Modal>
    </Space>
  );
};

export default FeedbackPage;
