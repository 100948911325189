import React, { useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Form, Col, Row, Typography, Space } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContentModel } from '@app/models/ContentModel';
import { getProfilePhoto } from '@app/utils/utils';
import { postContent, updateUser } from '@app/api/admin.api';
import { notificationController } from '@app/controllers/notificationController';
import { UserModel } from '@app/models/UserModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

interface LocationState {
  record: UserModel;
}

const EditUserPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { state } = useLocation();
  const record = state && (state as LocationState).record;

  const onFinish = (values: any) => {
    record.height = `${values.feet}' ${values.inches}"`

    const combinedValues = { ...record, ...values }
    // Destructure values and filter out feet, inches and profile_picture
    const { feet, inches, profile_picture, ...updatedUser } = combinedValues;

    updateUser(updatedUser)
      .then(res => {
        notificationController.success({ duration: 2000, message: 'User updated successfully.' });
        navigate(-1)
      })
      .catch(error => {
        notificationController.error({ message: error.message });
      })
  };

  return (
    <Space size={'large'} direction={'vertical'}>
      <Typography.Title level={3}>Edit User</Typography.Title>
      <Card>
        <Form
          name={'notification-form'}
          layout={'vertical'}
          initialValues={record}
          onFinish={onFinish}
          autoComplete={'off'}
        >
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24} md={12}>
              <Form.Item
                name={'name'}
                label={'Name'}
                rules={[{ required: true, message: 'Please input your name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'email'}
                label={'Email'}
                rules={[{ required: true, message: 'Please input your email.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'phone_number'}
                label={'Phone'}
              // rules={[{ required: true, message: 'Please input your phone no.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'age'}
                label={'Age'}
                rules={[{ required: true, message: 'Please input your age.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={'feet'}
                label={'Height (Feet)'}
                rules={[{ required: true, message: 'Please input your height.' }]}
                initialValue={record.height?.toString().split("'")[0] || ""}
              >
                <Input suffix="ft" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={'inches'}
                label={'Height (Inches)'}
                rules={[{ required: true, message: 'Please input your height.' }]}
                initialValue={record.height?.toString()?.split("'")[1]?.split('"')[0]?.trim() || ""}
              >
                <Input suffix="in" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'weight'}
                label={'Weight'}
                rules={[{ required: true, message: 'Please input your weight.' }]}
              >
                <Input suffix="lbs" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={'symptoms'}
                label={'Symptoms'}
                // rules={[{ required: true, message: 'Please input your symptoms!' }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name={'is_active'} valuePropName="checked" rules={[{ required: true }]}>
                <Checkbox>Is Active</Checkbox>
              </Form.Item>
            </Col>
            <Space />
            <Col xs={24} md={12}>
              <Form.Item wrapperCol={{ offset: 10 }}>
                <Button type={'ghost'} block htmlType={'submit'}>
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item wrapperCol={{ span: 14 }}>
                <Button danger type={'ghost'} block onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Space>
  );
};

export default EditUserPage;
