import React, { useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UserListModel, UserModel } from '@app/models/UserModel';
import { ColumnsType } from 'antd/es/table';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doGetUsers, setUsers } from '@app/store/slices/dataSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Avatar, Input, Modal, Space, Typography } from 'antd';
import { EditOutlined, InfoOutlined, MailOutlined } from '@ant-design/icons';
import { Card as CommonCard } from '@app/components/common/Card/Card';
import { getModeDisplay, getProfilePhoto, secondsToString } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '@app/api/admin.api';
import { Switch } from '@app/components/common/Switch/Switch';

const UserInfoModal = ({ userData, onClose }: { userData: UserListModel, onClose: any }) => {
  if (!userData) { return null }
  const user = userData.user;

  return <Modal
    title={user.name !== '' ? user.name : 'No Name'}
    visible={user !== null}
    onCancel={() => onClose()}
    footer={[
      <Button key="back" onClick={() => onClose()}>
        Close
      </Button>
    ]}>
    <p><b>Email: </b>{user.email}</p>
    <p><b>Age: </b>{user.age} {user.age && "years"}</p>
    <p><b>Height: </b>{user.height}</p>
    <p><b>Weight: </b>{user.weight} {user.weight && "lbs"}</p>
    <p><b>Phone: </b>{user.phone_number}</p>
    <p><b>Gender: </b>{user.gender === 0 ? 'Male' : 'Female'}</p>
    <p><b>Symptoms: </b>{user.symptoms}</p>
    <p><b>Current Mode: </b>{getModeDisplay(userData.user_mode)}</p>
    <p><b>Total Sitting Time: </b>{userData.time_spent > 0 ? secondsToString(userData.time_spent) : '0 hrs'}</p>
  </Modal>
}

const UsersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useAppSelector((state) => state.data.users);

  const [isLoading, setLoading] = useState(false);

  const [keyWord, setKeyWord] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const [userForModal, setUserForModal] = useState<UserListModel>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setLoading(true);
    dispatch(doGetUsers())
      .unwrap()
      .then((res) => {
        dispatch(setUsers(res));
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  useEffect(() => {
    setFilteredUsers(keyWord != '' ? users.filter(user => {
      return user.user.name?.toLowerCase().indexOf(keyWord.toLowerCase()) > -1
        || user.user.email?.toLowerCase().indexOf(keyWord.toLowerCase()) > -1
        || user.user.symptoms?.toLowerCase().indexOf(keyWord.toLowerCase()) > -1
        || user.user.phone_number?.toLowerCase().indexOf(keyWord.toLowerCase()) > -1
    }) : users);
  }, [keyWord, users])

  const onDeactivate = (isActive: boolean, record: UserModel) => {
    console.log(record);
    const updatedUser = { ...record, is_active: isActive }
    updateUser(updatedUser)
      .then(res => {
        console.log('updateUser', res);
        const updatedUsers = users.map(user => (user.user.id === res.id ? {...user, user: res} : user));
        dispatch(setUsers(updatedUsers));
      })
      .catch(error => {
        notificationController.error({ message: error.message });
      })
  }

  const columns: ColumnsType<UserListModel> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: string, { user }: UserListModel) => (
        <>
          <Avatar src={getProfilePhoto(user.profile_picture)} alt="User" shape="circle" size={40} />
          <Typography.Text style={{ marginLeft: 14 }}>{user.name}</Typography.Text>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Phone',
      dataIndex: ['user', 'phone_number'],
    },
    {
      title: 'Age',
      dataIndex: 'age',
      render: (text: string, { user }: UserListModel) => (
        <Typography.Text>
          {user.age} {user.age !== null && 'years'}
        </Typography.Text>
      ),
    },
    {
      title: 'Height',
      dataIndex: ['user', 'height'],
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      render: (text: string, { user }: UserListModel) => (
        <Typography.Text>
          {user.weight} {user.weight !== null && 'lbs'}
        </Typography.Text>
      ),
    },
    // {
    //   title: 'Symptoms',
    //   dataIndex: 'symptoms',
    // },
    {
      title: 'Status',
      dataIndex: 'is_active',
      width: 120,
      render: (text: string, { user }: UserListModel) => {
        return (
          <Space>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={user.is_active}
              onChange={(checked) => onDeactivate(checked, user)}
              style={{ backgroundColor: user.is_active ? 'green' : 'red' }} />
          </Space>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '5%',
      render: (text: string, record: UserListModel) => {
        return (
          <Space>
            <Button size="small" type="primary" icon={<InfoOutlined />} onClick={() => setUserForModal(record)} />
            <Button size="small" type="primary" icon={<EditOutlined />} onClick={() => navigate(`edit/${record.user.id}`, { state: { record: record.user } })} />
            <Button size="small" type="primary" icon={<MailOutlined />} onClick={() => navigate(`notify`, { state: { record: record.user } })} />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5, marginBottom: 20 }}>
        <Typography.Title level={3}>
          Users
        </Typography.Title>
        <Input style={{ width: '50%' }} placeholder={'Search by keyword'} value={keyWord} onChange={e => setKeyWord(e.target.value)} />
      </div>
      <CommonCard id="basic-table" padding="1.25rem 1.25rem 0">
        <Table columns={columns} dataSource={filteredUsers} loading={isLoading} scroll={{ x: 800 }} />
      </CommonCard>
      <UserInfoModal userData={userForModal} onClose={() => setUserForModal(null)} />
    </>
  );
};

export default UsersPage;
