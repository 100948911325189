import React, { useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ColumnsType } from 'antd/es/table';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doGetContent, setContent } from '@app/store/slices/dataSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Image, Row, Space, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Card as CommonCard } from '@app/components/common/Card/Card';
import { getProfilePhoto } from '@app/utils/utils';
import { ContentModel } from '@app/models/ContentModel';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { deleteContent } from '@app/api/admin.api';

const ContentPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const content = useAppSelector((state) => state.data.content);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = () => {
    setLoading(true);
    dispatch(doGetContent())
      .unwrap()
      .then((res) => {
        dispatch(setContent(res));
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const columns: ColumnsType<ContentModel> = [
    {
      title: 'Media',
      dataIndex: 'media',
      width: 200,
      render: (text: string, record: ContentModel) => (
        <Image preview={false} height={140} src={getProfilePhoto(record.image)} />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      // width: '15%',
    },
    // {
    //   title: 'Content',
    //   dataIndex: 'body',
    //   width: '40%',
    //   render: (_: string, record: ContentModel) => {
    //     let text1 = record.body
    //     if(text1.length > 500) {
    //       text1 = text1.substring(0,500) + "...";
    //     }
    //     return (
    //       <Typography.Text>{text1}</Typography.Text>
    //     );
    //   },
    // },
    {
      title: 'Posted To',
      dataIndex: 'postedTo',
      width: '15%',
      render: (text: string, record: ContentModel) => {
        return (
          <Space direction="vertical">
            <Checkbox checked={record.available_in_about_screen} onClick={() => false} onChange={() => false}>
              About Us
            </Checkbox>
            <Checkbox checked={record.available_in_explore_screen} onClick={() => false} onChange={() => false}>
              Explore
            </Checkbox>
          </Space>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (text: string, record: ContentModel) => {
        return (
          <Space>
            <Button size="small" type="primary" icon={<DeleteOutlined />} onClick={() => {
              navigate(`edit`, { state: { record } });
            }}>
              {'Edit'}
            </Button>
            <Button danger size="small" type="primary" icon={<DeleteOutlined />} onClick={() => {
              deleteContent(record.id)
                .then((res) => {
                  console.log('res', res);
                  notificationController.success({ message: 'Content deleted successfully.' });
                  fetchContent()
                })
                .catch((error) => {
                  console.log('error: ', error);
                  notificationController.error({ message: error });
                });
            }}>
              {'Delete'}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Typography.Title level={3}>Content</Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              navigate('create');
            }}
          >
            {'Upload Content'}
          </Button>
        </Col>
      </Row>
      <CommonCard id="basic-table" padding="1.25rem 1.25rem 0">
        <Table
          columns={columns}
          dataSource={content}
          loading={isLoading}
          scroll={{ x: 800 }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {
          //       navigate(`edit`, { state: { record } });
          //     },
          //   };
          // }}
        />
      </CommonCard>
    </>
  );
};

export default ContentPage;
