import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserListModel } from '@app/models/UserModel';
import { getContent, getFeatureRequests, getUsers } from '@app/api/admin.api';
import { ContentModel } from '@app/models/ContentModel';
import { FeatureRequest } from '@app/pages/FeatureRequests/types';

export interface DataSlice {
  users?: UserListModel[];
  content?: ContentModel[];
  featureRequests?: FeatureRequest[];
}

const initialState: DataSlice = {
  users: [],
  content: [],
  featureRequests: []
};

export const doGetUsers = createAsyncThunk('data/doGetUsers', async () =>
  getUsers().then((res) => {
    return res;
  }),
);

export const doGetContent = createAsyncThunk('data/doGetContent', async () =>
  getContent().then((res) => {
    return res;
  }),
);

export const doGetFeatureRequests = createAsyncThunk('data/doGetFeatureRequests', async () =>
  getFeatureRequests().then((res) => {
    return res;
  }),
);

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserListModel[] | undefined>) => {
      state.users = action.payload;
    },
    setContent: (state, action: PayloadAction<ContentModel[] | undefined>) => {
      state.content = action.payload;
    },
    setFeatureRequests: (state, action: PayloadAction<FeatureRequest[] | undefined>) => {
      state.featureRequests = action.payload;
    },
  },
});

export const { setUsers, setContent, setFeatureRequests } = dataSlice.actions;

export default dataSlice.reducer;
