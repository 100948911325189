import React from 'react';
import {
  DashboardOutlined,
  FileAddOutlined,
  FileOutlined,
  MessageOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  roles?: string[];
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Analytics',
    key: 'dashboard',
    url: '/',
    icon: <DashboardOutlined />,
    roles: ['Admin'],
  },
  {
    title: 'common.users',
    key: 'users',
    url: '/users',
    icon: <UserOutlined />,
    roles: ['Admin', 'Support'],
  },
  {
    title: 'Feedback',
    key: 'feedback',
    url: '/feedback',
    icon: <MessageOutlined />,
    roles: ['Admin', 'Support'],
  },
  {
    title: 'common.content',
    key: 'content',
    url: '/content',
    icon: <FileAddOutlined />,
    roles: ['Admin', 'Support'],
  },
  {
    title: 'common.notification',
    key: 'notification',
    url: '/notification',
    icon: <NotificationOutlined />,
    roles: ['Admin', 'Support'],
  },
  {
    title: 'Feature Requests',
    key: 'featureRequests',
    url: '/feature-requests',
    icon: <FileOutlined />,
    roles: ['Admin', 'Support'],
  },
];
