import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

const BASE_URL = 'https://app.easecushion.com';
export const httpApi = axios.create({
  baseURL: BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  const token = readToken();
  if (token) {
    config.headers = { ...config.headers, Authorization: `token ${readToken()}` };
  }

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});

export interface ApiErrorData {
  message: string;
}
