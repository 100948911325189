import React from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Form, Col, Row, Radio, Typography, Space, FormInstance } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from '@app/components/common/buttons/Button/Button';
import { postNotification } from '@app/api/admin.api';
import { UserModel } from '@app/models/UserModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

export type Notification = {
  heading: string;
  message: string;
  type: string;
  user?: number;
};

interface LocationState {
  record: UserModel;
}

const initialNotificationValues: Notification = {
  heading: '',
  message: '',
  type: 'EMAIL',
};

const NotificationPage: React.FC = () => {

  const [form] = Form.useForm();
  const { state } = useLocation();
  const record = state && (state as LocationState).record;
  const navigate = useNavigate()

  const onFinish = (values: Notification) => {
    return new Promise(() => {

      // Add if specific user exists else none i-e sending to all
      if (record) {
        values.user = record.id
      }
      // console.log('NotificationPage:', values);
      postNotification(values)
        .then((res) => {
          console.log('res', res);
          notificationController.success({ message: 'Notification sent successfully' });
          form.resetFields()
          // navigate(-1);
        })
        .catch((error) => {
          console.log('error: ', error);
          notificationController.error({ message: error });
        });
    });
  };

  return (
    <Space size={'large'} direction={'vertical'}>
      <Typography.Title level={3}>Notifications</Typography.Title>
      {record && <Typography.Title level={5}>Sending to {record.name !== '' ? record.name : record.email}</Typography.Title>}
      {!record && <Typography.Title level={5}>Sending to all the users</Typography.Title>}
      <Card>
        <Form
          form={form}
          name={'notification-form'}
          layout={'vertical'}
          initialValues={initialNotificationValues}
          onFinish={onFinish}
          autoComplete={'off'}
        >
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24}>
              <Form.Item
                name={'heading'}
                label={'Title'}
                rules={[{ required: true, message: 'Please input your title!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={'message'}
                label={'Description'}
                rules={[{ required: true, message: 'Please input your description!' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name={'type'} label={'Send as'}>
                <Radio.Group>
                  <Radio checked value={'EMAIL'}>Email</Radio>
                  <Radio value={'PUSH'}>Push Notification</Radio>
                  <Radio value={'ALL'}>Both</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} md={record && 12}>
              <Form.Item wrapperCol={{ offset: 6, span: record ? 18 : 12 }}>
                <Button type={'ghost'} block htmlType={'submit'}>
                  Post
                </Button>
              </Form.Item>
            </Col>
            {record && <Col xs={24} md={12}>
              <Form.Item wrapperCol={{ span: 18 }}>
                <Button danger type={'ghost'} block onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>}
          </Row>
        </Form>
      </Card>
    </Space>
  );
};

export default NotificationPage;
