import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { UserModel } from '@app/models/UserModel';
import * as S from './ProfileInfo.styles';
import { getProfilePhoto } from '@app/utils/utils';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);

  const { t } = useTranslation();

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <Avatar shape="circle" src={getProfilePhoto(profileData.profile_picture)} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{profileData?.name}</S.Title>
      <S.Subtitle>{profileData?.email}</S.Subtitle>
    </S.Wrapper>
  ) : null;
};
