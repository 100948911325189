// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getToken, onMessage, getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { notificationController } from '../controllers/notificationController';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGIc3LPYeNXFqPpltGkXD1tkJYIrka26w",
  authDomain: "easecushion-10b14.firebaseapp.com",
  projectId: "easecushion-10b14",
  storageBucket: "easecushion-10b14.appspot.com",
  messagingSenderId: "755672979117",
  appId: "1:755672979117:web:9db26b077a28dfaa541824",
  measurementId: "G-6F870HKYLL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);
// Add the public key generated from the console here.
getToken(messaging, {vapidKey: "BP7pHNpP6MwSHoznED-fa1HlpsrJxUNDQNGd7NVD7C_dhQ9rVqXWrL-5zgZKRFYLQMNa5r1xFpIPmmyYJ3BDOyY"}).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // console.log({currentToken})
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    requestPermission()
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err.message);
  // ...
});

function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  })
}

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
  notificationController.info({
    message: payload.notification.body
  })
});

export const sendNotificationRequest = (data) => {
  // messaging.send({
  //   data,
  //   topic: 'Ease Cushion'
  // })
  //   .then((response) => {
  //     // Response is a message ID string.
  //     console.log('Successfully sent message:', response);
  //   })
  //   .catch((error) => {
  //     console.log('Error sending message:', error);
  //   });
}

